
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












.fullwidth-visual {
  width: 100%;
  object-fit: cover;
  background-color: $c-skin;
}
