
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































































































































.hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 13.5rem;
  clip-path: inset(100% -50% 0 -50%);
}

.hero__picture-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.hero__title {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
}

.hero__picture {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero__content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $c-beige;
  font-family: $ff-alt;
}

.hero__logo {
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 22px, xxl: 56px));

  svg {
    display: block;
    max-width: 100%;
    height: auto;
  }

  [data-logo] {
    path {
      opacity: 0;
    }
  }

  [data-baseline] {
    opacity: 0;
  }
}
