
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































$bp: m;

.text-banner,
[class*='text-banner--'] {
  color: $c-green;

  @include mq($bp) {
    display: flex;
  }
}

.text-banner__title {
  @extend %fw-normal;

  // prettier-ignore
  @include fluid(font-size,(xxs: 36px, xxl: 72px));

  // prettier-ignore
  @include fluid(line-height,(xxs: 40px, xxl: 72px));

  // prettier-ignore
  @include fluid(letter-spacing,(xxs: 0.4px, xxl: 1.7px));

  font-family: $ff-alt;
  font-style: italic;

  ::v-deep strong {
    @extend %fw-medium;

    font-style: normal;
  }

  @include mq($bp) {
    flex-shrink: 0;
    width: col(12, 20);
    margin-right: col(2, 20);
  }
}

.text-banner__text {
  @include mq($bp) {
    margin-top: 1.5rem;
  }
}
