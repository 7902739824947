
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















































.map-intro {
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 55px, xxl: 400px));
}

::v-deep {
  .map-intro__title {
    @extend %fw-light;

    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    font-style: italic;

    strong {
      @extend %fw-medium;

      font-style: normal;
    }

    .intro__title__line {
      &:nth-child(2) {
        transform: translateX(5%);
      }

      &:nth-child(3) {
        @extend %fw-medium;

        font-style: normal;
      }
    }
  }

  .map-intro__title__line {
    &:nth-child(2) {
      @extend %fw-medium;

      font-style: italic;
    }
  }
}

.intro__btn {
  display: inline-block;
  margin-top: 5rem;
}
