
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















































.location {
  color: $c-brown;
  background-color: $c-white;
}

.location__picture {
  // prettier-ignore
  @include fluid(height, (xxs: 210px, xxl: 455px));

  object-fit: cover;
  background-color: $c-skin;
}

.location__content {
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 78px, xxl: 128px));

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: col(2, 24);
  padding-left: col(2, 24);

  @include mq($from: s) {
    padding-right: col(3, 14);
    padding-left: col(3, 14);
  }
}

.location__title {
  // prettier-ignore
  @include fluid(margin-top, (xxs: 50px, xxl: 110px));
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 40px, xxl: 60px));
}

.location__logo {
  display: block;
  fill: currentColor;
}

.location__lead {
  align-self: flex-start;
  margin-bottom: 2rem;
}

.location__text {
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 40px, xxl: 60px));
  // prettier-ignore
  @include fluid(font-size, (xxs: 15px, xxl: 18px));

  align-self: flex-start;
  color: $c-skin;
  line-height: 1.55;
}

::v-deep {
  .wysiwyg {
    ul {
      text-align: left;
    }
  }
}
