
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































.home {
  padding-top: 0;
}

.map-scrollable-container {
  position: relative;
}

.locations {
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 45px, xxl: 236px));
}
