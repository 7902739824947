
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





























































































































































































































































































































































.f-w {
  fill: #fff;
}

.map-container {
  position: sticky;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  opacity: 0.3;
}

.map {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
}

.map__flanders {
  fill: transparent;
}

.map__wallonia {
  fill: #f9ede8;
}

.map__regions {
  stroke-width: 2;

  path {
    stroke: $c-skin;
  }
}

.map__provinces {
  path {
    fill: none;
    stroke: $c-skin;
  }
}

.map__ardennes {
  stroke: $c-skin;
  fill: $c-skin;
}

.region {
  fill-opacity: 0;
}

.marker {
  &.active {
    .marker__pin {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.marker__logo {
  fill: #274d1e;
  fill-rule: evenodd;
}

.marker__pin {
  transform-origin: 50% 100%;
  transition: all 1s ease-out;

  // transform: scale(0.41);
  // opacity: 0.5;
}

.marker__label {
  fill: #ceaa97;
  opacity: 0.6;
}
