
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























































.intro {
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 55px, xxl: 400px));

  margin-top: -100vh;
}

.lead-big {
  margin-bottom: 0.5rem;
}

::v-deep {
  .intro__title {
    @extend %fw-light;

    display: flex;
    flex-direction: column;

    strong {
      @extend %fw-medium;

      font-style: normal;
    }
  }

  .intro__title__line {
    margin-bottom: -0.025em;
    padding-top: 0.025em;
    font-style: italic;

    &:nth-child(2) {
      @extend %fw-medium;

      font-style: normal;
      transform: translateX(1%);

      @include mq($from: s) {
        transform: translateX(5%);
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.intro__btn {
  display: inline-block;
  margin-top: 5rem;
}
